import frog from './frog.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={frog} className="frog" alt="frog" />
        <h1>Lesson Frog</h1>
        <p>
          ..Coming Soon
        </p>
        
      </header>
    </div>
  );
}

export default App;
